import React from "react"
import layoutStyles from "../styles/components/layout.module.scss"
import legalStyles from "../styles/pages/legal.module.scss"
import Layout from "../components/Layout"
import useSiteMetadata from "../static_queries/useSiteMetadata"
import Header from "../components/Header"
import contactStyles from "../styles/components/contact.module.scss"
import { Link } from "gatsby"

export default function Privacybeleid(props) {
  const { title } = useSiteMetadata()
  const date = new Date()
  const year = date.getFullYear()
  return (
    <Layout>
      <Header page={props.page} title={title} />
      <section className={layoutStyles.content}>
        <div className={legalStyles.legal}>
          <div className="title-main title-policy">
            <h1>Cookie Policy</h1>
          </div>
          <main className="policy">
            <div>
              <p>
                This is the Cookie Policy for Porfolio Ashley Van Laer,
                accessible from ashleyvanlaer.be
              </p>
              <p>
                <strong>Welke partijen zijn er?</strong>
              </p>
              <p>
                In deze Cookie Policy (“Cookieverklaring”) wordt verstaan onder:
              </p>
              <p>
                Digital North BV, with registered office at Boudewijnlaan 96B/1, 2243
                Pulle, Belgium, registered under the company number BE0798.932.679
              </p>
              <p>
                "User", "you" or "you": any natural person (B2C) or legal entity
                (B2B) that interacts with Albrima via its online platform in a
                relationship of any kind stands or will stand.
              </p>
              <p>
                "Law": art. 129 Law concerning electronic communication, as
                amended by W 2012-07-10/04, art. 90, 017; (entry into force :
                04-08-2012) (
                <a href="http://www.ejustice.just.fgov.be/cgi_loi/change_lg.pl?language=nl&la=N&cn=2005061332&table_name=wet">
                  URL
                </a>
                ).
              </p>

              <p>
                <strong>What Are Cookies</strong>
              </p>

              <p>
                As is common practice with almost all professional websites this
                site uses cookies, which are tiny files that are downloaded to
                your computer, to improve your experience. This page describes
                what information they gather, how we use it and why we sometimes
                need to store these cookies. We will also share how you can
                prevent these cookies from being stored however this may
                downgrade or 'break' certain elements of the sites
                functionality.
              </p>

              <p>
                For more general information on cookies, please read{" "}
                <a href="https://www.cookieconsent.com/what-are-cookies/">
                  "What Are Cookies"
                </a>
                . Information regarding cookies from this Cookies Policy are
                from{" "}
                <a href="https://www.generateprivacypolicy.com/">
                  the Privacy Policy Generator
                </a>
                .
              </p>

              <p>
                <strong>How We Use Cookies</strong>
              </p>

              <p>
                We use cookies for a variety of reasons detailed below.
                Unfortunately in most cases there are no industry standard
                options for disabling cookies without completely disabling the
                functionality and features they add to this site. It is
                recommended that you leave on all cookies if you are not sure
                whether you need them or not in case they are used to provide a
                service that you use.
              </p>

              <p>
                <strong>Disabling Cookies</strong>
              </p>

              <p>
                You can prevent the setting of cookies by adjusting the settings
                on your browser (see your browser Help for how to do this). Be
                aware that disabling cookies will affect the functionality of
                this and many other websites that you visit. Disabling cookies
                will usually result in also disabling certain functionality and
                features of the this site. Therefore it is recommended that you
                do not disable cookies. This Cookies Policy was created with the
                help of the{" "}
                <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">
                  Cookies Policy Generator from CookiePolicyGenerator.com
                </a>
                .
              </p>
              <p>
                <strong>The Cookies We Set</strong>
              </p>

              <ul>
                <li>
                  <p>Forms related cookies</p>
                  <p>
                    When you submit data to through a form such as those found
                    on contact pages or comment forms cookies may be set to
                    remember your user details for future correspondence.
                  </p>
                </li>
              </ul>

              <p>
                <strong>Third Party Cookies</strong>
              </p>

              <p>
                In some special cases we also use cookies provided by trusted
                third parties. The following section details which third party
                cookies you might encounter through this site.
              </p>

              <ul>
                <li>
                  <p>
                    This site uses Google Analytics which is one of the most
                    widespread and trusted analytics solution on the web for
                    helping us to understand how you use the site and ways that
                    we can improve your experience. These cookies may track
                    things such as how long you spend on the site and the pages
                    that you visit so we can continue to produce engaging
                    content.
                  </p>
                  <p>
                    For more information on Google Analytics cookies, see the
                    official Google Analytics page.
                  </p>
                </li>
              </ul>

              <p>
                <strong>More Information</strong>
              </p>

              <p>
                Hopefully that has clarified things for you and as was
                previously mentioned if there is something that you aren't sure
                whether you need or not it's usually safer to leave cookies
                enabled in case it does interact with one of the features you
                use on our site.
              </p>

              <p>
                However if you are still looking for more information then you
                can contact us through one of our preferred contact methods:
              </p>

              <ul>
                <li style={{color:"#fff"}}>Email: a.ashley.van.laer@gmail.com</li>
              </ul>
            </div>
          </main>
        </div>
        <p className={contactStyles.copyright}>
           ©{year} Digital North BV • BE0798.932.679 • All rights reserved -
          <Link to="/cookie-policy"> Cookie policy </Link>-
          <Link to="/privacy-policy"> Privacy policy </Link>
        </p>
      </section>
    </Layout>
  )
}
